import {useEffect} from 'react';
import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {useResponseMessagesContext} from "../../global-contexts/ResponseMessageContext";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../global-contexts/AuthContext";


const ENDPOINT = process.env.REACT_APP_ENDPOINT;

export interface CustomAxiosError extends AxiosError<unknown, AxiosResponse<unknown>> {
    code: string,
    message: string,
    response: {
        data:
            {
                "errors": [
                    {
                        "field": string,
                        "message": string,
                    }
                ]
            } & { "detail": string };
        status: number;
        statusText: string;
        headers: any;
        config: any;
    };
}

const useCustomAxiosInstance = (): { customAxiosInstance: AxiosInstance } => {
    const {addMessage} = useResponseMessagesContext()
    const access_token = localStorage.getItem('@access_token');
    const navigate = useNavigate();
    const {setIsAuthenticated} = useAuth();


    const customAxiosInstance = axios.create({
        baseURL: ENDPOINT,
        headers: {
            'X-AUTH-TOKEN': access_token,
            'X-ROLE': 'ROLE_USER',
            'X-DESKTOP': true
        },
        // withCredentials: true,
    });

    useEffect(() => {
        //Function called by interceptor when response status <2xx
        //TODO set success message -> missing structure from backend as of 2024-11-06
        const handleResponse = (response: any) => {
            if (response.data.status === 'ko') {
                response.data.message && addMessage({message: response.data.message});
                response.data.error && addMessage({message: response.data.error});
                if (response.data.error == 'No active subscription found') {
                    localStorage.clear();
                    navigate('/login');
                }
            }

            return response
        };

        //Function called by interceptor when response status >2xx
        //Adds message to context's state from backend's response error msg
        const handleError = (error: CustomAxiosError): Promise<never> => {
            if (error.response) {
                if (error.response.status === 403) {
                    setIsAuthenticated(false);
                    console.log('token expired')
                    navigate('/login');
                    return Promise.reject(error)
                }
                let message = ''
                Object.keys(error.response.data).forEach((key) => {
                    const value = error.response.data.errors;
                    if (Array.isArray(value)) {
                        value.forEach((e) => {
                            message += e.field + ': ' + e.message + ' ';
                        });
                    }
                });
                if (error.response.status >= 400) {
                    if (error.response.data.detail) {
                        addMessage({message: error.response.data.detail});
                    }
                    return Promise.reject(error)
                }
                addMessage({message: message});
            } else {
                addMessage({message: 'Network error'});
            }

            return Promise.reject(error);
        };

        const interceptor = customAxiosInstance.interceptors.response.use(handleResponse, handleError);

        return () => {
            customAxiosInstance.interceptors.response.eject(interceptor);
        };
    }, [addMessage, customAxiosInstance]);

    return {customAxiosInstance: customAxiosInstance};
};

export default useCustomAxiosInstance;
